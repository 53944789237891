<template>
  <div id="printable">
    <v-data-table
      :headers="headers"
      :items="identifierOrder.returns"
      sort-by="name"
      class="elevation-1"
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>Returns</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn elevation="9" rounded color="grey" @click="printReturnItems">
            <v-icon left>
              mdi-printer
            </v-icon>
            Print
          </v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template #item="{ item }">
        <tr>
          <td>
            {{ item.identifier }}
          </td>
          <td>
            {{ identifierOrder.identifier }}
          </td>
          <td>
            {{ item.created_at | date_format }}
          </td>
          <td>
            {{ identifierOrder.masterOrder.buyerCompany.company_name }}
          </td>
          <td>
            {{ identifierOrder.masterOrder.buyerUser | fullName }}
          </td>
          <td>
            <v-icon
              small
              class="mr-2"
              @click="$router.push('/market/return-detail/' + item.identifier)"
            >
              mdi-eye
            </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  filters: {
    fullName(user) {
      return (user.FirstName || '') + ' ' + (user.LastName || '')
    },
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      })
      return formatter.format(value)
    },
  },
  props: {
    identifierOrder: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    headers: [
      { text: 'Return ID' },
      { text: 'Order ID' },
      { text: 'Date' },
      { text: 'Business Name' },
      { text: 'User' },
      // { text: 'Status' },
      { text: 'Actions', sortable: false },
    ],
  }),
  methods: {
    printReturnItems() {
      window.document.body.innerHTML = document.getElementById(
        'printable'
      ).innerHTML
      window.print()
      window.close()
      window.location.reload(true)
    },
  },
}
</script>
