<template>
  <!--begin::Container-->
  <div v-if="identifierOrder" class="wrapper">
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="250px"
        lazy-src="/media/biz-header.jpg"
        src="/media/test-biz-header.png"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
        <div class="container " style="padding-bottom: 65px;">
          <div class="md-layout justify-md-center ">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
            >
              <div class="lato-header  font-weight-bolder text-white"></div>
              <div class="mt-5">
                <h4 class="text-white "></h4>
              </div>
            </div>
          </div>
        </div>
      </v-img>
    </div>

    <div class="main main-raised" style="background:#eee;">
      <div class="container">
        <div class="text-lg-right">
          <v-btn
            small
            elevation="9"
            rounded
            color="grey"
            style="margin-right: 20px;"
            @click="$router.go(-1)"
          >
            <v-icon left>
              mdi-arrow-left
            </v-icon>
            Back
          </v-btn>
          <!-- invoice will be created automatically. -->
          <!-- <v-btn
            elevation="9"
            rounded
            small
            color="success"
            @click="
              $router.push(
                '/market/create-invoice/' + parseInt($route.params.id)
              )
            "
            v-if="order.status !== 'invoiced' && order.status !== 'completed'"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            Invoice Order
          </v-btn> -->

          <v-btn
            v-if="order.status !== 'canceled'"
            elevation="9"
            rounded
            small
            color="red"
            @click="cancelOrder"
          >
            <v-icon left>
              mdi-cancel
            </v-icon>
            Cancel Order
          </v-btn>
          <v-btn
            v-if="order.status !== 'canceled'"
            elevation="9"
            rounded
            small
            color="primary"
            @click="$router.push('/market/ship-order/' + $route.params.id)"
          >
            <v-icon left>
              mdi-package
            </v-icon>
            Ship & Invoice Order
          </v-btn>
          <v-btn
            v-if="order.status !== 'completed'"
            elevation="9"
            rounded
            small
            color="primary"
            @click="$router.push('/market/return-order/' + $route.params.id)"
          >
            <v-icon left>
              mdi-package
            </v-icon>
            Return Order
          </v-btn>
        </div>
      </div>

      <div>
        <div class="row no-gutters mt-4">
          <div class="col-sm mr-1">
            <md-card class=" bg-primary h-100">
              <md-card-content>
                <div class="text-white">Order #:</div>
                <div class="text-white display-2">{{ $route.params.id }}</div>
              </md-card-content>
            </md-card>
          </div>
          <div class="col-sm mr-1">
            <md-card class="bg-secondary h-100">
              <md-card-content>
                <div class="text-white">Sold To:</div>
                <div class="text-white display-2">
                  {{ order.masterOrder.buyerCompany.company_name }}
                </div>
              </md-card-content>
            </md-card>
          </div>

          <div class="col-sm mr-1">
            <md-card class="bg-secondary h-100">
              <md-card-content>
                <div class="text-white">Order Date:</div>
                <div class="text-white display-2">
                  {{ order.masterOrder.created_at | date_format }}
                </div>
              </md-card-content>
            </md-card>
          </div>

          <div class="col-sm mr-1">
            <md-card class="bg-secondary h-100">
              <md-card-content>
                <div class="text-white">Status:</div>
                <div class="text-white display-2">
                  {{ order.status }}
                </div>
              </md-card-content>
            </md-card>
          </div>
          <div v-if="order.status === 'cancelRequested'" class="col-sm mr-1">
            <md-card class="bg-danger h-100">
              <md-card-content>
                <div class="text-white">
                  Note:
                  {{
                    identifierOrder.cancelRequests[
                      identifierOrder.cancelRequests.length - 1
                    ].note
                  }}
                </div>
              </md-card-content>
            </md-card>
          </div>
          <div v-if="order.status === 'returnRequested'" class="col-sm mr-1">
            <md-card class="bg-danger h-100">
              <md-card-content>
                <div class="text-white" style="text-transform:capitalize;">
                  Reason:
                  {{
                    identifierOrder.returnRequests[
                      identifierOrder.returnRequests.length - 1
                    ].reason
                  }}
                </div>
                <div class="text-white">
                  Return Items:
                  {{
                    identifierOrder.returnRequests[
                      identifierOrder.returnRequests.length - 1
                    ].detail
                  }}
                </div>
                <div class="text-white">
                  Note:
                  {{
                    identifierOrder.returnRequests[
                      identifierOrder.returnRequests.length - 1
                    ].note
                  }}
                </div>
              </md-card-content>
            </md-card>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <v-toolbar color="#eee" light flat>
            <v-tabs v-model="model">
              <v-tab>
                <v-icon left>
                  mdi-account
                </v-icon>
                Order Info
              </v-tab>
              <v-tab>
                <v-icon left>
                  mdi-lock
                </v-icon>
                Invoices
              </v-tab>
              <v-tab>
                <v-icon left>
                  mdi-access-point
                </v-icon>
                Shipments
              </v-tab>
              <v-tab>
                <v-icon left>
                  mdi-access-point
                </v-icon>
                Returns
              </v-tab>
              <!-- <v-tab>
                <v-icon left>
                  mdi-access-point
                </v-icon>
                Returns Requested
              </v-tab> -->
            </v-tabs>
          </v-toolbar>

          <v-tabs-items v-model="model">
            <v-tab-item>
              <OrderDetails
                :identifier-order="identifierOrder"
                @refetch="refetch"
              />
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-container>
                  <OrderInvoices :identifier-order="identifierOrder" />
                </v-container>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-container>
                  <OrderShipments :identifier-order="identifierOrder" />
                </v-container>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <OrderReturnItems :identifier-order="identifierOrder" />
            </v-tab-item>
            <!-- <v-tab-item>
              <OrderReturnsRequested :identifierOrder="identifierOrder" />
            </v-tab-item> -->
          </v-tabs-items>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderInvoices from "../../../components/market/OrderInvoices.vue";
import OrderShipments from "../../../components/market/OrderShipments.vue";
import OrderDetails from "@/components/market/OrderDetails.vue";
import OrderReturnItems from "@/components/market/OrderReturnItems.vue";
import GetOrderByIdentifier from "@/graphql/queries/GetOrderByIdentifier.gql";
import UpdateHistoricalStatus from "@/graphql/mutations/UpdateHistoricalStatus.gql";
import Swal from "sweetalert2";
import moment from "moment";

// import OrderReturnsRequested from "@/components/market/OrderReturnsRequested.vue";

export default {
  components: {
    OrderInvoices,
    OrderShipments,
    OrderDetails,
    OrderReturnItems
    // OrderReturnsRequested
  },
  filters: {
    currentStatus(historicalStatus) {
      var sorted = historicalStatus.map(status => ({ ...status }));
      sorted.sort((a, b) => {
        new Date(a.date) - new Date(b.date);
      });
      return sorted.length > 0 ? sorted[sorted.length - 1].status : "";
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      });
      return formatter.format(value);
    },
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    }
  },
  data: () => ({
    seller_company_name: "",
    baseurl: process.env.VUE_APP_STRAPI_API_URL || "http://localhost:1337",
    model: "tab-2"
  }),
  apollo: {
    identifierOrder: {
      query: GetOrderByIdentifier,
      variables() {
        return {
          identifier: this.$route.params.id
        };
      }
    }
  },
  computed: {
    order() {
      return {
        ...this.identifierOrder,
        status: this.$options.filters.currentStatus(
          this.identifierOrder.historicalStatus
        )
      };
    }
  },
  methods: {
    async refetch() {
      await this.$apollo.queries.identifierOrder.refetch();
    },
    async cancelOrder() {
      const order = this.identifierOrder.id;
      const newStatus = {
        status: "canceled",
        date: new Date().toISOString()
      };
      const statuses = [
        ...this.identifierOrder.historicalStatus,
        ...[newStatus]
      ].map(s => ({
        ...s,
        __typename: undefined
      }));

      await this.$apollo.mutate({
        mutation: UpdateHistoricalStatus,
        variables: { order, statuses }
      });
      // todo cancel auth on balance?
      await this.$apollo.queries.identifierOrder.refetch();

      await Swal.fire({
        title: "",
        text: "Order cancel requested.",
        icon: "success",
        confirmButtonClass: "btn btn-secondary"
      });
    }
  }
};
</script>
