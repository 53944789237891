<template>
  <div id="printable">
    <div>
      <v-card flat>
        <v-card-text>
          <div class="row ">
            <div class="col-lg-4">
              <v-card class="card card-custom card-stretch">
                <div class="card-header">
                  <div class="card-title">
                    <h3 class="card-label">Billing Info</h3>
                  </div>
                </div>
                <div class="card-body">
                  <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer</h5> -->

                  <p class="mb-2">
                    {{ identifierOrder.billToAddress.company_name }}<br />
                    {{ identifierOrder.billToAddress.address_line1 }}<br />
                    {{ identifierOrder.billToAddress.address_line2 }}<br />
                    {{ identifierOrder.billToAddress.city }}
                    {{ identifierOrder.billToAddress.state }}
                    {{ identifierOrder.billToAddress.zip_code }}
                  </p>
                  <p class="mb-2">
                    <span class="font-weight-semibold mr-2">Phone:</span>
                    {{ identifierOrder.billToAddress.phone }}
                  </p>
                </div>
              </v-card>
            </div>
            <!-- end col -->
            <div class="col-lg-4">
              <v-card class="card card-custom card-stretch">
                <div class="card-header">
                  <div class="card-title">
                    <h3 class="card-label">Shipping Info</h3>
                  </div>
                </div>
                <div class="card-body">
                  <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer Recieving</h5> -->

                  <p class="mb-2">
                    {{ identifierOrder.shipToAddress.company_name }}<br />
                    {{ identifierOrder.shipToAddress.address_line1 }}<br />
                    {{ identifierOrder.shipToAddress.address_line2 }}<br />
                    {{ identifierOrder.shipToAddress.city }}
                    {{ identifierOrder.shipToAddress.state }}
                    {{ identifierOrder.shipToAddress.zip_code }}
                  </p>
                  <p class="mb-2">
                    <span class="font-weight-semibold mr-2">Phone:</span>
                    {{ identifierOrder.shipToAddress.phone }}
                  </p>
                </div>
              </v-card>
            </div>
            <!-- end col -->

            <div class="col-lg-4">
              <v-card flat>
                <ul class="list-unstyled mb-0">
                  <li>
                    <p class="mb-2">
                      <span class="font-weight-semibold mr-2">Date:</span>
                      {{ identifierOrder.created_at | date_format }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-2">
                      <span class="font-weight-semibold mr-2"
                        >Purchase Order #:</span
                      >
                      {{ identifierOrder.po }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-2">
                      <span class="font-weight-semibold mr-2"
                        >Payment Type:</span
                      >
                      NET TERMS
                    </p>
                  </li>
                </ul>
              </v-card>
            </div>
            <!-- end col -->
          </div>
        </v-card-text>
      </v-card>
    </div>

    <!-- end row -->

    <div class="row  ">
      <div class="col-lg-12">
        <v-card class="card card-custom card-stretch">
          <div class="card-header card-header-tabs-line">
            <div class="card-title">
              <h3 class="card-label">
                Items from Order #{{ $route.params.id }}
              </h3>
            </div>
            <div class="card-toolbar"></div>
          </div>

          <div class="card-body">
            <div class="tab-content">
              <div
                id="kt_tab_pane_1_3"
                class="tab-pane fade show active"
                role="tabpanel"
                aria-labelledby="kt_tab_pane_1_3"
              >
                <!--begin: Datatable-->
                <table
                  id="kt_datatable"
                  class="table  table-checkable"
                  style="margin-top: 13px !important"
                >
                  <thead>
                    <tr>
                      <th>Line ID</th>
                      <th>SKU</th>
                      <!-- <th>Thumb</th> -->
                      <th>Item</th>
                      <th>Order Date</th>
                      <th>Qty</th>

                      <th>Cost</th>
                      <th>Extended Cost</th>
                      <!-- <th>Status</th> -->
                    </tr>
                  </thead>
                  <tbody v-if="identifierOrder.orderItems.length > 0">
                    <tr
                      v-for="(item, idx) in identifierOrder.orderItems"
                      :key="idx"
                    >
                      <td>{{ item.line }}</td>
                      <td>{{ item.sku }}</td>
                      <!-- <td>
                        <v-img
                          class="d-flex align-self-center mr-3 "
                          :src="
                            item.url != null
                              ? baseurl + item.url
                              : '/media/rollerskate.png'
                          "
                          alt="Generic placeholder image"
                          width="50"
                          height="50"
                        >
                        </v-img>
                      </td> -->
                      <td>
                        {{ item.product.name }}
                        <span v-if="item.name != 'default'"
                          >({{ item.name }})</span
                        >
                      </td>
                      <td>{{ item.created_at | date_format }}</td>
                      <td width="200px" class="text-align-right">
                        Sold: {{ item.quantity }} <br />
                        <div
                          v-if="
                            item.invoiceItems.reduce(
                              (a, c) => a + c.quantity,
                              0
                            ) > 0
                          "
                        >
                          Invoiced:
                          {{
                            item.invoiceItems.reduce(
                              (a, c) => a + c.quantity,
                              0
                            )
                          }}
                          <br />
                        </div>
                        <div
                          v-if="
                            item.returnItems.reduce(
                              (a, c) => a + c.quantity,
                              0
                            ) > 0
                          "
                        >
                          Returned:
                          {{
                            item.returnItems.reduce((a, c) => a + c.quantity, 0)
                          }}
                        </div>
                      </td>

                      <td>{{ item.unitPrice | dollar_format }}</td>
                      <td>
                        {{ item.lineTotal | dollar_format }}
                      </td>
                      <!-- <td>
                            <span v-if="item.status != null">{{item.status}}</span>
                            <span v-else>Pending</span>
                          </td> -->
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>

                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Subtotal</td>
                      <td>
                        {{ identifierOrder.subtotal | dollar_format }}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>

                      <td></td>
                      <td></td>
                      <td>Shipping:</td>
                      <td>
                        {{ identifierOrder.shiptotal | dollar_format }}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>

                      <td></td>
                      <td></td>
                      <td>Total</td>
                      <td>
                        {{ identifierOrder.total | dollar_format }}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <!--end: Datatable-->
                <div class="row">
                  <div class="col-lg-6"></div>
                  <div class="col-lg-6"></div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <!-- end row -->

    <div class="row mt-9">
      <!-- end col -->

      <div class="col-lg-6">
        <div class="card card-custom card-stretch">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Order Status History</h3>
            </div>
            Current status:
            {{ identifierOrder.historicalStatus | currentStatus }}

            <div>
              <v-select v-model="order_status" :items="statusList"></v-select>
              <v-btn @click="updateStatus"> update </v-btn>
            </div>
          </div>

          <v-timeline align-top dense>
            <v-timeline-item
              v-for="(log, index) in identifierOrder.historicalStatus"
              :key="index"
              color="pink"
              small
            >
              <v-row class="pt-1">
                <v-col cols="3">
                  <strong>{{ log.date | date_format }}</strong>
                </v-col>
                <v-col>
                  <strong>{{ log.status }}</strong>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end Row -->
  </div>
</template>

<script>
import moment from 'moment'

export default {
  filters: {
    currentStatus(historicalStatus) {
      var sorted = historicalStatus.map((status) => ({ ...status }))
      sorted.sort((a, b) => {
        new Date(a.date) - new Date(b.date)
      })
      return sorted.length > 0 ? sorted[sorted.length - 1].status : ''
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      })
      return formatter.format(value)
    },
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    },
  },
  props: {
    identifierOrder: {
      type: Object,
      default: () => ({}),
    },
  },

  data: function() {
    return {
      order_current_status: '',
      order_status: 'pending',
      statusList: [
        'pending',
        'processing',
        'backorder',
        'shippingDelay',
        'futureDelay',
        'cancelRequested',
      ],
      message: '',
      baseurl: process.env.VUE_APP_STRAPI_API_URL || 'http://localhost:1337',
    }
  },
}
</script>
