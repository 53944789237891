<template>
  <div id="printable">
    <div>
      <v-card flat>
        <v-card-text>
          <div class="row ">
            <div class="col-lg-4">
              <v-card class="card card-custom card-stretch">
                <div class="card-header">
                  <div class="card-title">
                    <h3 class="card-label">Billing Info</h3>
                  </div>
                </div>
                <div class="card-body">
                  <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer</h5> -->

                  <p class="mb-2">
                    {{ identifierOrder.billToAddress.company_name }}<br />
                    {{ identifierOrder.billToAddress.address_line1 }}<br />
                    {{ identifierOrder.billToAddress.address_line2 }}<br />
                    {{ identifierOrder.billToAddress.city }}
                    {{ identifierOrder.billToAddress.state }}
                    {{ identifierOrder.billToAddress.zip_code }}
                  </p>
                  <p class="mb-2">
                    <span class="font-weight-semibold mr-2">Phone:</span>
                    {{ identifierOrder.billToAddress.phone }}
                  </p>
                </div>
              </v-card>
            </div>
            <!-- end col -->
            <div class="col-lg-4">
              <v-card class="card card-custom card-stretch">
                <div class="card-header">
                  <div class="card-title">
                    <h3 class="card-label">Shipping Info</h3>
                  </div>
                </div>
                <div class="card-body">
                  <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer Recieving</h5> -->

                  <p class="mb-2">
                    {{ identifierOrder.shipToAddress.company_name }}<br />
                    {{ identifierOrder.shipToAddress.address_line1 }}<br />
                    {{ identifierOrder.shipToAddress.address_line2 }}<br />
                    {{ identifierOrder.shipToAddress.city }}
                    {{ identifierOrder.shipToAddress.state }}
                    {{ identifierOrder.shipToAddress.zip_code }}
                  </p>
                  <p class="mb-2">
                    <span class="font-weight-semibold mr-2">Phone:</span>
                    {{ identifierOrder.shipToAddress.phone }}
                  </p>
                </div>
              </v-card>
            </div>
            <!-- end col -->

            <div class="col-lg-4">
              <v-card flat>
                <ul class="list-unstyled mb-0">
                  <li>
                    <p class="mb-2">
                      <span class="font-weight-semibold mr-2">Date:</span>
                      {{ identifierOrder.created_at | date_format }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-2">
                      <span class="font-weight-semibold mr-2"
                        >Purchase Order #:</span
                      >
                      {{ identifierOrder.po }}
                    </p>
                  </li>
                  <li>
               
                  </li>
                  <li>
                    <v-btn
                      elevation="9"
                      rounded
                      color="grey"
                      @click="printOrders"
                    >
                      <v-icon left>
                        mdi-printer
                      </v-icon>
                      Print Order
                    </v-btn>
                  </li>
                </ul>
              </v-card>
            </div>
            <!-- end col -->
          </div>
        </v-card-text>
      </v-card>
    </div>

    <!-- end row -->

    <div class="row  ">
      <div class="col-lg-12">
        <v-card class="card card-custom card-stretch">
          <div class="card-header card-header-tabs-line">
            <div class="card-title">
              <h3 class="card-label">
                Items from Order #{{ $route.params.id }}
              </h3>
            </div>
            <div class="card-toolbar"></div>
          </div>

          <div class="card-body">
            <div class="tab-content">
              <div
                id="kt_tab_pane_1_3"
                class="tab-pane fade show active"
                role="tabpanel"
                aria-labelledby="kt_tab_pane_1_3"
              >
                <!--begin: Datatable-->
                <table
                  id="kt_datatable"
                  class="table  table-checkable"
                  style="margin-top: 13px !important"
                >
                  <thead>
                    <tr>
                      <th>Line ID</th>
                      <th>SKU</th>
                      <!-- <th>Thumb</th> -->
                      <th>Item</th>
                      <th>Order Date</th>
                      <th>Qty</th>

                      <th>Cost</th>
                      <th>Extended Cost</th>
                      <!-- <th>Status</th> -->
                    </tr>
                  </thead>
                  <tbody v-if="identifierOrder.orderItems.length > 0">
                    <tr
                      v-for="(item, idx) in identifierOrder.orderItems"
                      :key="idx"
                    >
                      <td>{{ item.line }}</td>
                      <td>{{ item.sku }}</td>
                      <!-- <td>
                        <v-img
                          class="d-flex align-self-center mr-3 "
                          :src="
                            item.url != null
                              ? baseurl + item.url
                              : '/media/rollerskate.png'
                          "
                          alt="Generic placeholder image"
                          width="50"
                          height="50"
                        >
                        </v-img>
                      </td> -->
                      <td>
                        {{ item.product.name }}
                        <span v-if="item.name != 'default'"
                          >({{ item.name }})</span
                        >
                      </td>
                      <td>{{ item.created_at | date_format }}</td>
                      <td width="200px" class="text-align-right">
                        Sold: {{ item.quantity }} <br />
                        <div
                          v-if="
                            item.invoiceItems.reduce(
                              (a, c) => a + c.quantity,
                              0
                            ) > 0
                          "
                        >
                          Invoiced:
                          {{
                            item.invoiceItems.reduce(
                              (a, c) => a + c.quantity,
                              0
                            )
                          }}
                          <br />
                        </div>
                        <div
                          v-if="
                            item.returnItems.reduce(
                              (a, c) => a + c.quantity,
                              0
                            ) > 0
                          "
                        >
                          Returned:
                          {{
                            item.returnItems.reduce((a, c) => a + c.quantity, 0)
                          }}
                        </div>
                      </td>

                      <td>{{ item.unitPrice | dollar_format }}</td>
                      <td>
                        {{ item.lineTotal | dollar_format }}
                      </td>
                      <!-- <td>
                            <span v-if="item.status != null">{{item.status}}</span>
                            <span v-else>Pending</span>
                          </td> -->
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>

                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Subtotal</td>
                      <td>
                        {{ identifierOrder.subtotal | dollar_format }}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>

                      <td></td>
                      <td></td>
                      <td>Shipping:</td>
                      <td>
                        {{ identifierOrder.shiptotal | dollar_format }}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>

                      <td></td>
                      <td></td>
                      <td>Total</td>
                      <td>
                        {{ identifierOrder.total | dollar_format }}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <!--end: Datatable-->
                <div class="row">
                  <div class="col-lg-6"></div>
                  <div class="col-lg-6"></div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <!-- end row -->

    <div class="row mt-9">
      <div class="col-lg-6">
        <div class="card card-custom bg-light card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0">
            <h3 class="card-title font-weight-bold text-dark">
              Send
              {{ identifierOrder.masterOrder.buyerCompany.company_name }}
              a message
            </h3>
            <div class="card-toolbar">
              <div
                class="dropdown dropdown-inline"
                data-toggle="tooltip"
                title=""
                data-placement="left"
                data-original-title="Quick actions"
              >
                <a
                  href="#"
                  class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="ki ki-bold-more-hor"></i>
                </a>
                <div
                  class="dropdown-menu dropdown-menu-md dropdown-menu-right"
                  style=""
                >
                  <!--begin::Navigation-->
                  <ul class="navi navi-hover py-5">
                    <li class="navi-item">
                      <a href="#" class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-drop"></i>
                        </span>
                        <span class="navi-text">New Group</span>
                      </a>
                    </li>
                    <li class="navi-item">
                      <a href="#" class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-list-3"></i>
                        </span>
                        <span class="navi-text">Contacts</span>
                      </a>
                    </li>
                    <li class="navi-item">
                      <a href="#" class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-rocket-1"></i>
                        </span>
                        <span class="navi-text">Groups</span>
                        <span class="navi-link-badge">
                          <span
                            class="label label-light-primary label-inline font-weight-bold"
                            >new</span
                          >
                        </span>
                      </a>
                    </li>
                    <li class="navi-item">
                      <a href="#" class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-bell-2"></i>
                        </span>
                        <span class="navi-text">Calls</span>
                      </a>
                    </li>
                    <li class="navi-item">
                      <a href="#" class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-gear"></i>
                        </span>
                        <span class="navi-text">Settings</span>
                      </a>
                    </li>
                    <li class="navi-separator my-3"></li>
                    <li class="navi-item">
                      <a href="#" class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-magnifier-tool"></i>
                        </span>
                        <span class="navi-text">Help</span>
                      </a>
                    </li>
                    <li class="navi-item">
                      <a href="#" class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-bell-2"></i>
                        </span>
                        <span class="navi-text">Privacy</span>
                        <span class="navi-link-badge">
                          <span
                            class="label label-light-danger label-rounded font-weight-bold"
                            >5</span
                          >
                        </span>
                      </a>
                    </li>
                  </ul>
                  <!--end::Navigation-->
                </div>
              </div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-2">
            <!--begin::Form-->

            <div class="form-group">
              <div class="dropdown mb-10">
                <button
                  id="dropdownMenuButton"
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  Message Topic
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item">Backorder</a>
                  <a class="dropdown-item">Tracking</a>
                  <a class="dropdown-item">Defect</a>
                </div>
              </div>

              <textarea
                v-model="message"
                class="form-control border-0"
                name="memo"
                rows="4"
                placeholder="Message"
              ></textarea>
            </div>
            <div class="mt-10">
              <v-btn @click="contactBuyer">Send</v-btn>
            </div>
            <!--end::Form-->
          </div>
          <!--end::Body-->
        </div>
      </div>
      <!-- end col -->

      <div class="col-lg-6">
        <div class="card card-custom card-stretch">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Order Status History</h3>
            </div>
            Current status:
            {{ identifierOrder.historicalStatus | currentStatus }}

            <div>
              <v-select v-model="order_status" :items="statusList"></v-select>
              <v-btn @click="updateStatus"> update </v-btn>
            </div>
          </div>

          <v-timeline align-top dense>
            <v-timeline-item
              v-for="(log, index) in identifierOrder.historicalStatus"
              :key="index"
              color="pink"
              small
            >
              <v-row class="pt-1">
                <v-col cols="3">
                  <strong>{{ log.date | date_format }}</strong>
                </v-col>
                <v-col>
                  <strong>{{ log.status }}</strong>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </div>
      </div>
      <!-- end col -->
    </div>
    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="order"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
    >
      <section slot="pdf-content">
        <print-order :identifier-order="identifierOrder" />
      </section>
    </vue-html2pdf>
    <!-- end Row -->
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import UpdateHistoricalStatus from "@/graphql/mutations/UpdateHistoricalStatus.gql";
import { matrixClient } from "@/core/services/matrix";
import { mapGetters } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
import PrintOrder from "@/components/supplier/PrintOrder.vue";

export default {
  components: {
    VueHtml2pdf,
    PrintOrder,
  },
  filters: {
    currentStatus(historicalStatus) {
      var sorted = historicalStatus.map((status) => ({ ...status }));
      sorted.sort((a, b) => {
        new Date(a.date) - new Date(b.date);
      });
      return sorted.length > 0 ? sorted[sorted.length - 1].status : "";
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
  },
  props: {
    identifierOrder: {
      type: Object,
      default: () => ({}),
    },
  },

  data: function() {
    return {
      order_current_status: "",
      order_status: "pending",
      statusList: [
        "pending",
        "processing",
        "backorder",
        "shippingDelay",
        "futureDelay",
        "cancelRequested",
      ],
      message: "",
      baseurl: process.env.VUE_APP_STRAPI_API_URL || "http://localhost:1337",
    };
  },
  computed: {
    ...mapGetters(["roomsByAlias"]),

    selectedRoom() {
      if (!this.$route.params.id || !this.identifierOrder) {
        return null;
      }
      const roomAlias =
        "#" +
        this.identifierOrder.masterOrder.buyerCompany.identifier +
        "<=>" +
        this.identifierOrder.vendorCompany.identifier +
        ":" +
        process.env.VUE_APP_MATRIX_URL_BASE_DOMAIN;
      // console.log(roomAlias);
      return this.roomsByAlias[roomAlias];
    },
  },
  methods: {
    ...mapActions(["getCompanyInfo"]),
    async updateStatus() {
      const newStatus = {
        status: this.order_status,
        date: new Date().toISOString(),
      };
      const statuses = []
        .concat(this.identifierOrder.historicalStatus, [newStatus])
        .map((s) => ({
          ...s,
          __typename: undefined,
        }));
      await this.$apollo.mutate({
        mutation: UpdateHistoricalStatus,
        variables: { order: this.identifierOrder.id, statuses },
      });
      this.$emit("refetch");
      Swal.fire({
        title: "",
        text: "Updated successfully.",
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });
    },
    async contactBuyer() {
      try {
        if (!this.selectedRoom) {
          await this.createRoom();
        }
        await this.sendFullMessage();

        this.message = "";

        Swal.fire({
          title: "",
          text: "The message has been successfully sent!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
      } catch (error) {
        // console.log(error);
      }
    },
    async sendFullMessage() {
      const message =
        "Message about vendor order #" +
        this.$route.params.id +
        " " +
        this.message;
      const content = {
        body: message,
        msgtype: "m.text",
      };
      return await matrixClient.sendEvent(
        this.selectedRoom.summary.roomId,
        "m.room.message",
        content,
        ""
      );
    },
    async createRoom() {
      // create room
      const buyerCompanyMatrixUsers = this.identifierOrder.masterOrder.buyerCompany.company_users.map(
        (u) => `@goose${u.id}:${process.env.VUE_APP_MATRIX_URL_BASE_DOMAIN}`
      );
      const userCompanyMatrixUsers = this.identifierOrder.vendorCompany.company_users
        .filter((u) => u.id !== this.$store.state.auth.user.id)
        .map(
          (u) => `@goose${u.id}:${process.env.VUE_APP_MATRIX_URL_BASE_DOMAIN}`
        );
      // need to create a room that can be found later by using the identifiers.
      // are both names human friendly?
      // it is always buyer first then seller.
      const roomName =
        this.identifierOrder.masterOrder.buyerCompany.company_name +
        " & " +
        this.identifierOrder.vendorCompany.company_name;
      const roomAlias =
        this.identifierOrder.masterOrder.buyerCompany.identifier +
        "<=>" +
        this.identifierOrder.vendorCompany.identifier;

      await matrixClient.createRoom({
        name: roomName,
        room_alias_name: roomAlias,
        visibility: "private",
        invite: [...buyerCompanyMatrixUsers, ...userCompanyMatrixUsers],
        preset: "trusted_private_chat",
      });
    },
    printOrders() {
      window.scrollTo(0, 0);
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>
